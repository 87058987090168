<template>
  <div>
    <h3>Completed</h3>
    <p>
      At this point, the survey would be checked for Qualification and the
      participant would be directed to the appropriate page based on if he/she
      qualified
    </p>
  </div>
</template>
<script>
export default {
  name: "Confirmation",
};
</script>
