<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <p
          class="acceptance-terms"
          v-if="$t('acceptanceTerms').length > 0"
          v-html="acceptanceTermsDisplay"
        ></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pt-0">
        <validation-provider
          v-slot="{ errors }"
          :vid="oFormFieldId"
          :rules="{ acceptTerms: { allowFalse: false } }"
        >
          <v-checkbox
            v-model="acceptanceValue"
            :label="$t('label')"
            :error-messages="errors"
          ></v-checkbox>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";

import labelParsing from "@shared/mixins/surveys/questions/labelParsing";

export default {
  name: "AcceptanceCriteriaRendered",
  components: { ValidationProvider },
  mixins: [labelParsing],
  props: {
    value: {
      required: true,
    },
    oFormFieldId: {
      type: String,
      required: true,
    },
    oFormFieldTitle: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
      default: () => {},
    },
    serverErrors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  i18n: {
    messages: {},
  },
  watch: {
    acceptanceTerms: {
      handler(value) {
        this._i18n.setLocaleMessage(
          "en",
          Object.assign({}, this._i18n.messages.en ?? {}, value.en)
        );

        for (const target of this.languageTargets) {
          this._i18n.setLocaleMessage(
            target.value,
            Object.assign(
              {},
              this._i18n.messages[target.value] ?? {},
              value[target.value] ?? {}
            )
          );
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("systemLanguages", ["languageTargets"]),
    acceptanceTermsDisplay() {
      return this.$t("acceptanceTerms");
    },
    acceptanceTerms() {
      const acceptanceTerms = {};

      for (const target of this.languageTargets) {
        acceptanceTerms[target.value] = {};

        if (Object.hasOwn(this.configuration, target.value + "Terms")) {
          acceptanceTerms[target.value].acceptanceTerms = this.configuration[
            target.value + "Terms"
          ].replaceAll(/\r\n|\n/g, "<br>");
        }
      }

      //override en if exists, this is the default
      acceptanceTerms["en"].acceptanceTerms = Object.hasOwn(
        this.configuration,
        "terms"
      )
        ? this.configuration.terms.replaceAll(/\r\n|\n/g, "<br>")
        : "";

      return acceptanceTerms;
    },
    acceptanceValue: {
      get() {
        return this.value === "1";
      },
      set(value) {
        // Store checkbox boolean values as either 1 or 0 to be compatible with how the DB should store data
        this.$emit("input", value ? "1" : "0");
      },
    },
  },
  mounted() {
    //when clicking back this question, we want to emit that a selection has been made so the 'Next' button is active
    if (this.acceptanceValue) {
      this.$emit("input", this.acceptanceValue ? "1" : "0");
    }
  },
};
</script>

<style scoped></style>
<script setup></script>
