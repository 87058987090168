<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="warning"
      timeout="-1"
      outlined
      top
      right
      transition="slide-x-reverse-transition"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <v-card-text>
      <v-card class="mx-auto mb-12">
        <section-header :isCollapsable="false">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4"
                ><span>{{ this.survey.title }} </span>
                <template v-if="showSurveyVersionToggle">
                  <v-switch
                    :disabled="isLoading || isPreviewLoading"
                    v-model="showPreviousVersion"
                    label="Current Approved Version"
                  ></v-switch>
                </template>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="float-right">
                  <template
                    v-if="isSurveyApproved && showActivateOrDeactiveButton"
                  >
                    <v-btn
                      @click="toggleSurveyActive()"
                      :loading="isToggleActive"
                      class="mr-2"
                      outlined
                      :disabled="snackbar && !focusedSurvey.isActive"
                      :color="focusedSurvey.isActive ? 'error' : 'success'"
                      >{{
                        focusedSurvey.isActive ? "Deactivate" : "Activate"
                      }}</v-btn
                    >
                  </template>
                  <template v-if="showDeleteButton">
                    <v-btn
                      color="error"
                      class="mr-2"
                      @click="remove"
                      outlined
                      :loading="isEditing"
                    >
                      Delete Survey
                      <v-icon aria-label="Delete Survey"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <template v-if="isSurveyApproved">
                    <v-btn color="success" outlined class="mr-2">
                      Approved
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <template v-else-if="showApprovedButton">
                    <v-btn
                      class="mr-2"
                      color="success"
                      outlined
                      @click="approveSurveyVersion()"
                      :loading="isApproveProcessing"
                    >
                      Approve Draft
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <template v-if="!showPreviousVersion">
                    <v-btn
                      v-if="isSurveyApproved && showCreateNewSurveyButton"
                      color="primary"
                      class="mr-2"
                      @click="createNewSurveyVersion()"
                      :loading="isEditing"
                      >New Version<v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else-if="showEditButton"
                      :to="{
                        name: 'SurveysEdit',
                        params: { id: focusedSurvey.id },
                      }"
                      color="primary"
                      class="mr-2"
                      :disabled="isEditing || isApproveProcessing"
                      :loading="isEditing || isApproveProcessing"
                    >
                      Edit Survey
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    color="warning"
                    class="mr-2"
                    @click="
                      exportToPDF([
                        focusedSurveyVersion.identity,
                        showPreviousVersion,
                      ])
                    "
                    :loading="isEditing || isApproveProcessing"
                  >
                    Export PDF
                    <v-icon>mdi-file-export</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Sponsor:</dt>
                <dd v-text="focusedSurvey.sponsorName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Engagement:</dt>
                <dd v-text="engagementName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Status:</dt>
                <dd v-text="status"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-row align="center">
            <v-col>
              <h2>
                Preview<span v-if="!isPreviewLoading && surveyPage.title">
                  - {{ surveyPage.title }}</span
                >
              </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-select
                label="Language"
                :items="languageTargets"
                v-model="previewLanguageTarget"
              ></v-select>
            </v-col>
          </v-row>
          <hr class="mb-2" />
          <template v-if="!isPreviewLoading">
            <page-rendered
              v-if="!showPreviewConfirmation"
              class="preview-page-rendered"
              @completeQualification="changeShowPreviewConfirmation"
            ></page-rendered>
            <preview-confirmation v-else></preview-confirmation>
          </template>
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { setI18nLanguage } from "@utils/plugins/i18n";

import { localize as validationLocalization } from "vee-validate";
import PageRendered from "@shared/components/surveys/PageRendered";
import PreviewConfirmation from "@admin/components/surveys/PreviewConformation";
import ConfirmDialog from "@utils/components/ConfirmDialog";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { date_format } from "@shared/util/ValidateDate";
import SectionHeader from "@admin/components/SectionHeader.vue";

// Ensure validation rules needed for survey questions when previewing a survey are available
extend("date_format", {
  ...date_format,
  message: (fieldName, { locale }) => {
    return (
      "Must match format " +
      // date-fns locales represent year format items as just 'y', and we want users to be under the impression
      // that they have to type 'yyyy' because that is what we validate for in date_format
      locale.formatLong.date({ width: "short" }).replace(/y+/g, "yyyy")
    );
  },
});

extend("required", { ...required, message: "This field is required" });

export default {
  name: "SurveysView",
  components: {
    SectionHeader,
    PageRendered,
    PreviewConfirmation,
    ConfirmDialog,
  },
  props: {
    identity: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isPreviewLoading: true,
      isToggleActive: false,
      isApproveProcessing: false,
      isEditing: false,
      showPreviousVersion: false,
      // Determine whether or not the preview confirmation page should be shown. If
      // not, we will continue to attempt to show the preview
      showPreviewConfirmation: false,
      previewLanguageTarget: "en",
      snackbar: false,
      snackbarMessage:
        "Media Channels must be deleted before a survey is deleted.",
    };
  },
  computed: {
    engagementName() {
      return this.focusedSurvey.engagementName;
    },
    status() {
      return this.surveyIsActive ? "Active" : "Inactive";
    },
    ...mapState("surveys", ["survey", "previousApprovedSurvey"]),
    ...mapState("engagements", ["engagement"]),
    ...mapGetters("surveys", { getSurveyVersion: "surveyVersion" }),
    ...mapGetters("surveySubmissions", ["surveyPage"]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    ...mapGetters(["acceptRoles"]),

    showApprovedButton() {
      return this.acceptRoles(["Manager", "Admin", "Super User"]);
    },
    showDeleteButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showEditButton() {
      return this.acceptRoles(["Admin", "Manager", "Editor", "Super User"]);
    },
    showSurveyVersionToggle() {
      // Only show the version toggle if the current survey isn't approved
      // and if there is actually a previous survey to be able to load
      return !this.surveyVersion?.isApproved && this.previousApprovedSurvey.id;
    },
    surveyVersion() {
      return this.getSurveyVersion(this.survey.oFormVersionId);
    },
    showActivateOrDeactiveButton() {
      return this.acceptRoles(["Admin", "Manager", "Editor", "Super User"]);
    },
    showCreateNewSurveyButton() {
      return this.acceptRoles(["Admin", "Manager", "Editor", "Super User"]);
    },
    previousApprovedSurveyVersion() {
      // If there is no previous approved survey actually loaded, state
      // won't have a valid version ID for it. Return null to indicate there is no version
      if (!this.previousApprovedSurvey.oFormVersionId) {
        return null;
      }

      return this.getSurveyVersion(this.previousApprovedSurvey.oFormVersionId);
    },
    focusedSurvey() {
      return this.showPreviousVersion
        ? this.previousApprovedSurvey
        : this.survey;
    },
    focusedSurveyVersion() {
      return this.showPreviousVersion
        ? this.previousApprovedSurveyVersion
        : this.surveyVersion;
    },
    surveyIsActive() {
      return this.focusedSurvey.isActive;
    },
    isSurveyApproved() {
      return this.focusedSurveyVersion && this.focusedSurveyVersion.isApproved;
    },
  },
  watch: {
    // Allow for changing the i18n language target for preview purposes. When leaving this context,
    // ensure the language gets setback to 'en' to not conflict with the rest of the system
    previewLanguageTarget(newTarget) {
      this.setPreviewLanguage(newTarget);
    },
    showPreviousVersion(showPreviousVersion) {
      this.isPreviewLoading = true;

      // If toggling whether the previous version is shown or not, reload the preview state for showing a
      // submission based on if a previous approved version should be shown or not. Being able to even show a previous
      // version indicates that there is a previous approved version of the survey while the current version isn't.
      // We can reflect this in preview mode by indicating that only an approved version should be loaded (which will
      // be the previous version)
      this.refreshSurveySubmissionState();
      this.toggleSurveySubmissionPreview(true);
      this.getSurveySubmissionSurveyByIdentity({
        identity: this.identity,
        approvedOnly: showPreviousVersion ? 1 : 0,
      }).then(() => {
        this.isPreviewLoading = false;
      });
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("surveys", {
      deleteSurvey: "deleteSurvey",
      submitApproveSurveyVersion: "approveSurveyVersion",
      submitToggleSurveyActive: "toggleSurveyActive",
      submitTogglePreviousApprovedSurveyActive:
        "togglePreviousApprovedSurveyActive",
      submitCreateNewSurveyVersion: "createNewSurveyVersion",
      getSurveyByIdentity: "getSurveyByIdentity",
      getPreviousApprovedSurveyByIdentity:
        "getPreviousApprovedSurveyByIdentity",
      refreshSurvey: "clearSurvey",
      refreshSurveyVersions: "clearSurveyVersions",
      exportToPDF: "exportToPDF",
    }),
    ...mapActions("surveySubmissions", {
      toggleSurveySubmissionPreview: "togglePreviewPage",
      refreshSurveySubmissionState: "refreshState",
      getSurveySubmissionSurveyByIdentity: "getSurveyByIdentity",
    }),
    ...mapActions("engagements", {
      getEngagement: "getEngagement",
    }),
    ...mapActions("systemLanguages", {
      getLanguagesList: "getLanguagesList",
    }),
    hasRmsProvider() {
      //if survey is inactive and there are active surveys, then disabled input until all engagement surveys are not active anymore
      this.getEngagement(this.survey.engagementId).then(() => {
        if (this.engagement.rmsProviderName === "Not Defined") {
          this.snackbar = true;
          this.snackbarMessage =
            "Survey cannot be activated until an RMS provider for the engagement has ben selected";
        }
      });
    },
    changeShowPreviewConfirmation() {
      // Toggle whether or not we are showing the preview confirmation page
      this.showPreviewConfirmation = !this.showPreviewConfirmation;
    },
    setPreviewLanguage(language) {
      validationLocalization(language);
      // Don't affect the document's overall language since we are just providing translating functionality
      // for a nested part of the overall document
      setI18nLanguage(language, { changeDocumentLang: false });
    },
    approveSurveyVersion() {
      if (this.isApproveProcessing) return;

      this.$refs.confirm
        .open(
          "Approve this Survey?",
          "Once approved, a survey cannot be edited, but a user can copy the existing survey to create a new draft version"
        )
        .then((confirm) => {
          if (confirm) {
            this.isApproveProcessing = true;
            this.submitApproveSurveyVersion().then(() => {
              this.isApproveProcessing = false;
            });
          }
        });
    },
    toggleSurveyActive() {
      // Only allow the toggling of a survey to happen one at a time, even if multiple versions are loaded and are able
      // to be toggled active (this technically should never happen since we only allow for toggling approved surveys, and
      // technically only one approved survey can be loaded at a time)
      if (this.isToggleActive) return;

      this.$refs.confirm
        .open(
          this.survey.isActive ? "Remove Active Status?" : "Set Survey Active?",
          this.survey.isActive
            ? "If a survey's active status is removed, the survey will NO LONGER be accessible for media channels and consequently NOT be available to participants"
            : "If a survey is made active, it will be accessible for media channels and consequently be available to participants"
        )
        .then((confirm) => {
          if (confirm) {
            this.isToggleActive = true;

            const promises = [];

            // Switch which version we are toggling active based on which version has focus
            if (this.showPreviousVersion) {
              promises.push(this.submitTogglePreviousApprovedSurveyActive());
            } else {
              promises.push(this.submitToggleSurveyActive());
            }

            Promise.all(promises).then(() => {
              this.isToggleActive = false;
            });
          }
        });
    },
    createNewSurveyVersion() {
      // Don't allow for creating a new version of the survey if focusing on the previous version of the survey. We want to ensure the user understands
      // they are creating a new version of the current version of the survey when this button is used (which is how this button will work regardless of which
      // version is currently focused)
      if (this.showPreviousVersion || this.isEditing) return;

      this.$refs.confirm
        .open(
          "Create new Survey Version?",
          "Creating a new survey version will allow for making edits to the survey. The current version will be provided to participants until the new version" +
            " is approved"
        )
        .then((confirm) => {
          if (confirm) {
            this.isEditing = true;
            this.submitCreateNewSurveyVersion().then(([success]) => {
              this.isEditing = false;
              if (success) {
                this.$router.push({
                  name: "SurveysEdit",
                  params: { id: this.survey.id },
                });
              }
            });
          }
        });
    },
    // delete survey
    remove() {
      if (this.isEditing) return;

      this.$refs.confirm
        .open("Confirm", "Are you sure you want to delete this engagement?")
        .then((confirm) => {
          if (confirm) {
            this.isEditing = true;
            const engagementId = this.survey.engagementId;
            this.deleteSurvey(this.survey.id).then(([success]) => {
              this.isEditing = false;
              if (success) {
                this.$router.push({
                  name: "EngagementView",
                  params: { id: engagementId },
                });
              } else {
                this.snackbar = true;
              }
            });
          }
        });
    },
  },
  async beforeMount() {
    // Ensure the preview system's language is English by default to be consistent with the
    // default value of previewLanguageTarget
    this.setPreviewLanguage("en");
    this.getLanguagesList();
    this.toggleSurveySubmissionPreview(true);
    this.isLoading = true;
    this.isPreviewLoading = true;
    const surveyPromises = [];

    surveyPromises.push(this.getSurveyByIdentity({ identity: this.identity }));

    surveyPromises.push(
      this.getSurveySubmissionSurveyByIdentity({
        identity: this.identity,
        // When initially loading the survey, it isn't possible to have toggled to showing the previous version.
        // Always expect to show the forward most version of the survey in preview regardless of approval
        approvedOnly: 0,
      })
    );

    await Promise.all(surveyPromises).then((responses) => {
      let success = true;
      for (const [responseSuccess] of responses) {
        if (!responseSuccess) {
          success = false;
          break;
        }
      }

      if (!success) {
        this.$router.push({ name: "NotFound" });
      } else {
        this.isLoading = false;
        this.isPreviewLoading = false;
        // Getting a previous version of the survey so that an admin may toggle to view it
        // should not be blocking of mounting this component so nesting this promise is fine
        this.getPreviousApprovedSurveyByIdentity();
      }

      this.setTitleObject(this.survey.title);
      this.setEngagementBreadcrumb({
        id: this.survey.engagementId,
        name: this.survey.engagementName,
      });
      this.setSponsorBreadcrumb({
        id: this.survey.sponsorId,
        name: this.survey.sponsorName,
      });
      this.setBreadcrumbs();
    });
    this.hasRmsProvider();
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.refreshSurveySubmissionState();
    this.refreshSurvey();
    this.refreshSurveyVersions();
    // Ensure we set the language for the system back to English to not have unexpected side effects
    this.setPreviewLanguage("en");
  },
};
</script>

<style>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
.v-input--selection-controls {
  margin: 0;
}
/* The following styles are to ensure that no automatic vuetify styling is applied to what should be style free fields */
.preview-page-rendered button,
.preview-page-rendered input,
.preview-page-rendered select,
.preview-page-rendered textarea {
  background-color: revert;
  border-style: revert;
}

.preview-page-rendered button,
.preview-page-rendered input,
.preview-page-rendered optgroup,
.preview-page-rendered select,
.preview-page-rendered textarea {
  font: revert;
}

.preview-page-rendered p {
  margin-bottom: revert;
}

.preview-page-rendered input {
  border-radius: revert;
}

.preview-page-rendered select {
  -moz-appearance: revert;
  -webkit-appearance: revert;
}

.preview-page-rendered .field-error {
  color: red;
}
</style>
