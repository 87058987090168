<template>
  <date-field
    v-model="birthdate"
    :isRequired="true"
    :id="birthdateIdentifier"
    :label="$t('label')"
  >
  </date-field>
</template>

<script>
import { mapGetters } from "vuex";
import labelParsing from "@shared/mixins/surveys/questions/labelParsing";
import DateField from "@shared/components/DateField";

export default {
  name: "BirthdateRendered",
  components: { DateField },
  mixins: [labelParsing],
  props: {
    value: {
      required: true,
    },
    oFormFieldId: {
      type: String,
      required: true,
    },
    oFormFieldTitle: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
      default: () => {},
    },
    serverErrors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  i18n: {
    messages: {},
  },
  computed: {
    ...mapGetters("systemLanguages", ["languageTargets", "localeDateTarget"]),
    birthdateIdentifier() {
      return "survey-question-birthdate-" + this.oFormFieldId;
    },
    birthdate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    //when clicking back this question, we want to emit that a selection has been made so the 'Next' button is active
    if (this.birthdate) {
      this.$emit("input", this.birthdate);
    }
  },
};
</script>

<style scoped></style>
