<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="question-label">{{ $t("label") }}</p>
      </v-col>
    </v-row>
    <v-row v-for="option in options" :key="option.value">
      <v-col cols="12" sm="12">
        <v-btn
          rounded
          :color="selectValue === option.value ? 'darkBlue' : 'orangeBrown'"
          @click="selectValue = option.value"
          :class="[
            'white--text option-select pa-7',
            { selected: selectValue === option.value },
          ]"
          width="100%"
          ><span class="text-wrap">{{ option[optionLabel] }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import optionParsing from "@shared/mixins/surveys/questions/optionParsing";
import labelParsing from "@shared/mixins/surveys/questions/labelParsing";

export default {
  name: "SelectRendered",
  mixins: [optionParsing, labelParsing],
  props: {
    value: {
      required: true,
    },
    oFormFieldId: {
      type: String,
      required: true,
    },
    oFormFieldTitle: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
      default: () => {},
    },
    serverErrors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  i18n: {
    messages: {},
  },
  computed: {
    ...mapGetters("systemLanguages", ["languageTargets"]),
    selectIdentifier() {
      return "survey-question-select-" + this.oFormFieldId;
    },
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    //when clicking back this question, we want to emit that a selection has been made so the 'Next' button is active
    if (this.selectValue) {
      this.$emit("input", this.selectValue);
    }
  },
};
</script>

<style scoped></style>
