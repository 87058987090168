export default {
  watch: {
    // Vue's i18n library works where we need to specify what text we want to use for different languages. Normally,
    // this should be configured for a component ahead of time. However, since survey questions are dynamic, we have to see
    // what configuration was made for a question label before we can set it. This requires us to extract what labels
    // should be as a computed property, and, when it changes, we set the locale messages for i18n to represent
    // the found labels.
    // IMPORTANT: see the system_languages store for important notes on being able to specify these labels on a per
    // component basis
    labels: {
      handler(value) {
        for (const target of this.languageTargets) {
          this._i18n.setLocaleMessage(
            target.value,
            Object.assign(
              {},
              this._i18n.messages[target.value] ?? {},
              value[target.value] ?? {}
            )
          );
        }

        this._i18n.setLocaleMessage(
          "en",
          Object.assign({}, this._i18n.messages.en ?? {}, value.en)
        );
      },
      immediate: true,
    },
  },
  computed: {
    // Be able to identify the labels that should be used for survey questions when rendering them on a survey page
    // based on the languages that labels were provided for for the given question
    labels() {
      const labels = {};

      for (const target of this.languageTargets) {
        labels[target.value] = {};

        if (
          Object.hasOwn(this.configuration, target.value + "Title") &&
          this.configuration[target.value + "Title"].length > 0
        ) {
          labels[target.value].label =
            this.configuration[target.value + "Title"];
        }
      }

      //override en if exists, this is the default
      labels["en"].label = this.oFormFieldTitle;

      return labels;
    },
  },
};
