<template>
  <v-container v-if="isLoading || isSubmitting">
    <v-row justify="center">
      <v-col cols="12" sm="4">
        <v-skeleton-loader
          :loading="true"
          type="card-heading, list-item-three-line, button"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row justify="center">
      <v-col cols="12" sm="4">
        <v-toolbar dense max-height="50px" elevation="0">
          <v-container>
            <v-row v-if="hasSurveyPageHistory">
              <v-col cols="12" sm="12" class="pa-0">
                <v-btn
                  plain
                  @click="loadPreviousPage"
                  color="orangeBrown"
                  class="back-btn"
                >
                  <v-icon>mdi-arrow-left-thick</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="px-0">
                <v-progress-linear
                  background-color="#F4EDE6"
                  color="orangeBrown"
                  height="7"
                  rounded
                  :value="progressBarData.value"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="4">
        <validation-observer
          ref="survey_submission"
          v-slot="{ handleSubmit, invalid }"
        >
          <form @submit.prevent="handleSubmit(submitForm)">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                  <template v-for="surveyPageQuestion in surveyPageQuestions">
                    <!-- TODO need to implement concept of having a "fieldValue" once the concept of saving data for a survey is established -->
                    <question-rendered
                      :key="surveyPageQuestion.identity"
                      :oFormFieldId="surveyPageQuestion.identity"
                      :oFormFieldTitle="surveyPageQuestion.title"
                      :oFormFieldTypeId="surveyPageQuestion.oFormFieldTypeId"
                      :configuration="surveyPageQuestion.configuration"
                      :serverErrors="serverErrors"
                      :value="
                        surveyPageQuestionEntries[surveyPageQuestion.identity]
                      "
                      @input="
                        updateQuestionEntry(surveyPageQuestion.identity, $event)
                      "
                      @hideNextBtn="nextBtnDisplayed = false"
                    >
                    </question-rendered>
                  </template>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-btn
                    class="white--text pa-7 font-weight-bold"
                    color="orangeBrown"
                    rounded
                    type="submit"
                    :disabled="invalid || !optionSelected"
                    >{{ $t("nextButton") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.back-btn {
  background-color: transparent !important;
  border: 0 solid #000;
  justify-content: left !important;
  padding: 0 !important;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__avatar,
.theme--light.v-skeleton-loader .v-skeleton-loader__button,
.theme--light.v-skeleton-loader .v-skeleton-loader__chip,
.theme--light.v-skeleton-loader .v-skeleton-loader__divider,
.theme--light.v-skeleton-loader .v-skeleton-loader__heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__image,
.theme--light.v-skeleton-loader .v-skeleton-loader__text {
  background: #ad3410;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #ad341060 !important;
  color: #fff !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { ValidationObserver } from "vee-validate";
import QuestionRendered from "@shared/components/surveys/QuestionRendered";

// We're using the same getter and mutation types as were used in the survey_submissions store
const { mapFields: mapSurveyPageQuestionEntriesFields } = createHelpers({
  getterType: "getSurveyPageQuestionEntriesField",
  mutationType: "updateSurveyPageQuestionEntriesField",
});

export default {
  name: "PageRendered",
  components: { QuestionRendered, ValidationObserver },
  data() {
    return {
      alertShow: false,
      alertMessage: "",
      alertType: "error",
      isLoading: true,
      isSubmitting: false,
      optionSelected: false,
      serverErrors: {},
    };
  },
  i18n: {
    messages: {
      en: {
        backButton: "Back",
        nextButton: "Next",
      },
      es: {
        backButton: "Atrás",
        nextButton: "Siguiente",
      },
      ["es-US"]: {
        backButton: "Atrás",
        nextButton: "Siguiente",
      },
    },
  },
  computed: {
    ...mapGetters("surveySubmissions", [
      "progressBarData",
      "surveyPage",
      "surveyIsPreview",
      "surveyPageQuestions",
      "hasSurveySubmission",
      "hasSurveyPageHistory",
      "survey",
    ]),
    ...mapSurveyPageQuestionEntriesFields("surveySubmissions", [
      "surveyPageQuestionEntries",
    ]),
  },
  methods: {
    updateQuestionEntry(identity, value) {
      // In order to engage reactivity, we must reassign question entries as we are
      // setting property values
      this.surveyPageQuestionEntries = Object.assign(
        {},
        this.surveyPageQuestionEntries,
        {
          [identity]: value,
        }
      );
      this.optionSelected = value.length > 0;
    },
    ...mapActions("surveySubmissions", {
      getSurveyPage: "getSurveyPage",
      sanitizeSurveyPageHistory: "sanitizeSurveyPageHistory",
      snapshotSurveyPageHistory: "snapshotSurveyPageHistory",
      createSurveySubmission: "createSurveySubmission",
      createSurveyPageEntry: "createSurveyPageEntry",
      getPreviousSurveyPage: "getPreviousSurveyPage",
      editSurveyPageSubmission: "editSurveyPageSubmission",
      getSurveySubmissionQualified: "getSurveySubmissionQualified",
      incrementProgressBar: "incrementProgressBar",
      decrementProgressBar: "decrementProgressBar",
      getSurveySubmissionSurveyByIdentity: "getSurveyByIdentity",
      getMediaChannelForTransitionSurvey: "getMediaChannelForTransitionSurvey",
    }),
    async submitForm() {
      if (this.isSubmitting || !this.optionSelected) return;
      this.isSubmitting = true;
      this.incrementProgressBar();

      let hasSubmission = this.hasSurveySubmission;

      if (!this.surveyIsPreview && !this.hasSurveySubmission) {
        // If not working on a survey in preview mode, and there is not yet a submission for the survey, we need to create
        // one. This isn't done until submitting a form since we don't want to create a submission for the user until at least one rendered
        // page has been interacted with
        [hasSubmission] = await this.createSurveySubmission().catch(() => {
          // If a submission couldn't be created, indicate failure
          this.$emit("manageSurveyFailure");
          this.isLoading = true;

          return Promise.resolve([false]);
        });

        if (hasSubmission) {
          // Ordinarily, we'd expect a survey page entry to be established ahead of time, but, when a submission is just being created, this couldn't
          // have bene the case. We'll want to create an entry for whatever the current target survey page is, and then all future survey pages should
          // be created ahead of transitioning into them.
          // IMPORTANT: without the successful creation of an entry, we aren't able to save data against it. Hinge the concept of if we have a submission
          // on the success of creating this data so we don't try to save against it on failure
          [hasSubmission] = await this.createSurveyPageEntry().catch(() => {
            // If an initial page entry couldn't be created, indicate failure
            this.$emit("manageSurveyFailure");
            this.isLoading = true;

            return Promise.resolve([false]);
          });
        }
      }

      if (this.surveyIsPreview || hasSubmission) {
        await this.editSurveyPageSubmission()
          .then(([success, errors, data]) => {
            this.isSubmitting = false;

            if (success) {
              if (
                Object.hasOwn(data, "targetOFormContainerIdentity") &&
                data.targetOFormContainerIdentity
              ) {
                this.loadPage({
                  targetOFormContainerIdentity:
                    data.targetOFormContainerIdentity,
                  targetOFormContainerEntryId:
                    data.targetOFormContainerEntryId ?? null,
                });
              } else {
                // If we are in a state where we need to determine if the survey is qualified, we don't want the user to attempt to change data.
                // For this reason, we will indicate loading still to not show the user a survey page
                this.isLoading = true;
                // If no target container could be provided, consider the survey completed and redirect to an appropriate page based on
                // whether or not the survey submission is considered completed
                this.getSurveySubmissionQualified().then(
                  async ([success, , data]) => {
                    if (success) {
                      // Before handling qualification logic, take a final snapshot of survey page history to store details about the final page
                      // the user interacted with. Since we are leaving the survey context, the entire survey is considered history.
                      // TODO: if we ever redirect the user outside of the survey when we couldn't successfully determine qualification, this snapshot
                      // should be moved outside of the "success" scope
                      this.snapshotSurveyPageHistory();

                      //if survey has a transition then get the second page from the targeted "to" survey
                      if (
                        this.survey.transitions.to !== undefined &&
                        data.isQualified === false
                      ) {
                        const toOFormIdentity = this.survey.transitions.to;

                        //load media channel id from "to" transtion survey
                        const fromMediaChannelId =
                          this.survey.targetMediaChannelId;

                        //load the new targeted 'to' survey
                        await this.getSurveySubmissionSurveyByIdentity({
                          identity: toOFormIdentity,
                        });

                        //load the correct mediaChannel id that has matching domains from 'form' survey to 'to' survey
                        await this.getMediaChannelForTransitionSurvey({
                          toOFormIdentity: toOFormIdentity,
                          fromMediaChannelId: fromMediaChannelId,
                        });
                        //lets load the first page of the newly loaded survey (acknoweldgement page)
                        await this.loadPage();
                        //since the acknowledgement form was already answered in the 'from' survey before transtioning, then we don't want to ask the user again
                        await this.bypassAcknowledgement();

                        //set loading to false so we can resubmit this form and get the next question
                        this.isLoading = false;
                        await this.submitForm();
                      } else {
                        // If qualification could be evaluated, don't set isLoading to false (meaning the no page will be presented to the user)
                        // in favor of expecting the completeQualification event to be listened for and to direct the user to some other scope. There
                        // is nothing left to do for the survey at this point
                        this.$emit("completeQualification", {
                          isQualified:
                            Object.hasOwn(data, "isQualified") &&
                            data.isQualified,
                        });
                      }
                    } else {
                      // If we couldn't determine if the user was qualified or not, we might as well keep the user in the survey.
                      // TODO: we will likely want to change this to direct the user somewhere else. If we've reached this state, and we can't
                      // determine qualification, we won't be able to in the future and the survey should be considered moot
                      this.isLoading = false;
                    }
                  }
                );
              }
            } else if (errors) {
              this.$refs.survey_submission.setErrors(errors);
            }
          })
          .catch(() => {
            // If data couldn't be reviewed for the current page, indicate failure
            this.$emit("manageSurveyFailure");
            this.isLoading = true;
          });
      }

      this.isSubmitting = false;
    },
    async loadPreviousPage() {
      this.isLoading = true;
      let success = false;

      [success] = await this.getPreviousSurveyPage();

      if (success) {
        [success] = await this.sanitizeSurveyPageHistory();
      }

      // TODO if we can't successfully redirect to the previous page (and sanitize data),
      // nothing will be shown since we'll always be loading. We don't want to show anything
      // since we don't want to allow for incorrect data to be provided, but should we do
      // something to reflect this?
      if (success) {
        this.isLoading = false;
        this.decrementProgressBar();
      }
    },
    async loadPage({
      targetOFormContainerIdentity = null,
      targetOFormContainerEntryId = null,
    } = {}) {
      this.optionSelected = false;
      this.isLoading = true;

      let success = false;

      [success] = await this.getSurveyPage({
        targetOFormContainerIdentity,
        targetOFormContainerEntryId,
      });

      if (success) {
        [success] = await this.sanitizeSurveyPageHistory();
      }

      // TODO if we can't successfully redirect to a new page (and sanitize data),
      // nothing will be shown since we'll always be loading. We don't want to show anything
      // since we don't want to allow for incorrect data to be provided, but should we do
      // something to reflect this?
      if (success) {
        this.isLoading = false;
      }
    },
    // automatically agree to the acknowledgement question
    // this should only be used if transtioning to a new survey where the user already has answered a previous acknowledgement question
    async bypassAcknowledgement() {
      let firstKey = Object.keys(this.surveyPageQuestionEntries)[0];
      this.surveyPageQuestionEntries[firstKey] = true;
      this.optionSelected = true;
    },
  },
  mounted() {
    this.loadPage();
  },
  updated() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>
