<template>
  <div>
    <component
      v-if="formFieldTypeRender !== null"
      :is="formFieldTypeRender"
      :configuration="configuration"
      :serverErrors="serverErrors"
      :oFormFieldId="oFormFieldId"
      :oFormFieldTitle="oFormFieldTitle"
      v-model="questionValue"
    ></component>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import camelCase from "lodash.camelcase";
// Form field type specific render options
import AcceptanceCriteriaRendered from "@shared/components/surveys/questions/AcceptanceCriteriaRendered";
import MultiSelectRendered from "@shared/components/surveys/questions/MultiSelectRendered";
import SelectRendered from "@shared/components/surveys/questions/SelectRendered";
import BirthdateRendered from "@shared/components/surveys/questions/BirthdateRendered";

export default {
  name: "QuestionRendered",
  data() {
    return {
      metricSent: {},
    };
  },
  components: {
    AcceptanceCriteriaRendered,
    MultiSelectRendered,
    SelectRendered,
    BirthdateRendered,
  },
  props: {
    pages: {
      type: Array,
    },
    value: {
      required: true,
    },
    oFormFieldId: {
      type: String,
      required: true,
    },
    oFormFieldTitle: {
      type: String,
      required: true,
    },
    oFormFieldTypeId: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
      default: () => {},
    },
    serverErrors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("surveySubmissions", ["surveyIsPreview"]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    formFieldTypeRender() {
      if (
        this.oFormFieldTypeId !== null &&
        this.oFormFieldTypeId.length > 0 &&
        // Ensure the field type isn't "placeholder" since placeholder fields
        // shouldn't actually be rendered. They are meant to do nothing, and should
        // be resolved as not placeholder before a survey is even accessible to complete
        this.oFormFieldTypeId !== "placeholder"
      ) {
        const fieldType = camelCase(this.oFormFieldTypeId);
        const componentName =
          fieldType.charAt(0).toUpperCase() + fieldType.slice(1) + "Rendered";
        if (componentName in this.$options.components) {
          return componentName;
        }
      }
      // If we couldn't resolve an acceptable component to represent rendering the type of the current question,
      // don't attempt to render anything
      return null;
    },
    questionValue: {
      get() {
        return this.value;
      },
      set(value) {
        // call metric method to trigger api
        this.sendMetric(value);

        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("surveySubmissions", ["updateSurveyTempMetricData"]),
    sendMetric(value) {
      // Don't actually consider sending metrics if the survey is in preview mode.
      // the addMetric action should block actually creating the metric, but we don't
      // want to represent data in this scope either (e.g. if we aren't sending a metric
      // we don't want to indicate that a metric was sent)
      if (this.surveyIsPreview) return;

      // if this question metric was sent then don't send it again
      if (!this.metricSent[this.oFormFieldId]) {
        this.$set(this.metricSent, this.oFormFieldId, value);

        let startTime =
          this.$store.state.surveySubmissions.surveyPageQuestions[
            this.oFormFieldId
          ].startTime;

        let metricData = {
          start: startTime,
          stop: Date.now(),
          type: "question",
          identity: this.oFormFieldId,
          o_form_submission_id:
            this.$store.state.surveySubmissions.survey.submissionId,
        };

        //if there is no submission id yet, lets temporarily store the metric data and send at later date
        if (
          this.$store.state.surveySubmissions.survey.submissionId === undefined
        ) {
          this.updateSurveyTempMetricData(metricData);
        }
        //if there is metric data then lets send the temp data as well as the new metric data
        else if (
          this.$store.state.surveySubmissions.survey.tempMetricData !==
          undefined
        ) {
          let tempMetricData =
            this.$store.state.surveySubmissions.survey.tempMetricData;
          tempMetricData.o_form_submission_id =
            this.$store.state.surveySubmissions.survey.submissionId;

          this.$store.dispatch("addMetric", tempMetricData, { root: true });
          this.$store.dispatch("addMetric", metricData, { root: true });
          this.updateSurveyTempMetricData(undefined);
        } else {
          this.$store.dispatch("addMetric", metricData, { root: true });
        }
      }
    },
  },
};
</script>
