export default {
  computed: {
    optionLabel() {
      return this.$i18n.locale + "Label";
    },
    /*
    Identify which options should be available for option based survey questions (e.g. select).
    For each option provided, it is possible that an alternative language was specified for the option.
    
    Since select based fields require us to specify an array of objects that represent the options where a specific
    property will be indicated to represent the label, we can't rely on the i18n to change languages since it can't
    update the value of the property we target. We instead will simply identify different properties for each language
    and use i18n to switch between which property we target.

    IMPORTANT: since we rely on i18n to just switch between which property we target, we can't use it in a way where
    it changes messages for us and thus be able to handle fallback languages. E.g. if there is no label for es-US use es instead.
    For this reason, we have to identify a "fallbackValue" for each system language we manage that we want to be able to fallback
    to another language. This will work like i18n works where if an the label for a specific language isn't specified, we can check
    if one was specified for the "fallbackValue" i.e. the fallback locale. E.g. we can specify that es-US will fallback to es which
    will operate in the same way that i18n does
    */
    options() {
      const options = [];

      if (
        Object.hasOwn(this.configuration, "options") &&
        this.configuration.options.length > 0
      ) {
        for (const option of this.configuration.options) {
          if (
            Object.hasOwn(option, "label") &&
            Object.hasOwn(option, "value")
          ) {
            const optionContent = {
              value: option.value,
            };

            for (const target of this.languageTargets) {
              // If we can't resolve a label for a secondary language, we want to fallback to using the default label
              // (which is for English) by default to make sure that at least some label is shown
              optionContent[target.value + "Label"] = option.label;

              if (
                Object.hasOwn(option, target.value + "Label") &&
                option[target.value + "Label"].length > 0
              ) {
                optionContent[target.value + "Label"] =
                  option[target.value + "Label"];
              } else if (
                Object.hasOwn(target, "fallbackValue") &&
                Object.hasOwn(option, target.fallbackValue + "Label") &&
                option[target.fallbackValue + "Label"].length > 0
              ) {
                optionContent[target.value + "Label"] =
                  option[target.fallbackValue + "Label"];
              }
            }

            //override en if exists, this is the default
            optionContent["enLabel"] = option.label;

            options.push(optionContent);
          }
        }
      }

      return options;
    },
  },
};
