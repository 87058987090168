<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <validation-provider
          v-slot="{ errors }"
          :vid="dateFieldIdentifier"
          :rules="{
            required: isRequired,
            date_format: localeDateTarget($i18n.locale),
          }"
          :mode="delayedAggressive"
        >
          <v-text-field
            dense
            outlined
            :label="label"
            :id="dateFieldIdentifier"
            :value="value"
            v-model="dateValue"
            :error-messages="errors"
            hint="This should be in the format of mm/dd/yyyy"
            v-mask="'##/##/####'"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { format as dateFormat } from "date-fns";

import { translateDate, validateDate } from "@shared/util/ValidateDate";
import { mask } from "vue-the-mask";
/**
 * Provide a DateField that will provide a text based input and validate it to ensure a valid date string
 * is provided based on the current i18n locale the user is using.
 *
 * IMPORTANT: when saving data to an API, we want to save date based values in ISO8601 format i.e. yyyy-MM-dd.
 * In order to achieve this, we want to transform the user provided date string for whichever locale he/she is targeting
 * to be ISO based. We can only do this once a valid date is provided, though, or else transforming date values will
 * cause the user's input to be changed from what he/she is expecting. Additionally, while we traform the value to be ISO8601
 * compatible for setting the value (if the date is valid), we have to transform it back to the current locale's format
 * upon getting the value so that the validation rule always passes and the user sees what he/she expects
 */

export default {
  name: "DateField",
  components: { ValidationProvider },
  directives: { mask },
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    value: {
      // Value is expected to be provided to this component in a structure that is compatible with being able to segregate
      // date parts into multiple inputs. I.e. it should come as a set of year, month, and day
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("systemLanguages", ["localeDateTarget"]),
    dateFieldIdentifier() {
      return this.id ? this.id : "date-field-" + this._uid;
    },
    dateLocaleTarget() {
      return this.localeDateTarget(this.$i18n.locale);
    },
    dateValue: {
      get() {
        // return this.value;
        return this.interpretDateValue(this.value, false);
      },
      set(value) {
        // this.$emit("input", value);
        this.$emit("input", this.interpretDateValue(value));
      },
    },
  },
  methods: {
    // Provide a means to be given a date string value and format it into the desired
    interpretDateValue(value, transformUTC = true) {
      const date = translateDate(value, {
        locale: this.dateLocaleTarget,
        isUTC: !transformUTC,
      });

      if (date !== false && validateDate(date)) {
        return dateFormat(
          date,
          transformUTC
            ? "y-MM-dd"
            : this.dateLocaleTarget.formatLong.date({ width: "short" })
        );
      }

      return value;
    },
    delayedAggressive() {
      {
        return {
          on: ["input"],
          debounce: 1000,
        };
      }
    },
  },
  mounted() {
    //when clicking back this question, we want to emit that a selection has been made so the 'Next' button is active
    if (this.dateValue) {
      this.$emit("input", this.dateValue);
    }
  },
};
</script>
